export default (data = {}) => {
    return {
        account: null,
        puc_code: null,
        puc_name: null,
        niif_code: null,
        niif_name: null,
        groupName: null,
        Request_1: null,
        Request_2: null,
        Request_3: null,
        Request_4: null,
        account_type: null,
        pyg: null,
        currency: null,
        ...data
    }
}