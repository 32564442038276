var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-sheet',{staticClass:"pa-2 mt-3",attrs:{"color":"white","elevation":"20"}},[_c('v-data-table',{attrs:{"headers":_vm.headers[_vm.$vuetify.breakpoint.xsOnly],"items":_vm.accounts,"items-per-page":10,"item-key":"puc_code","sort-by":"issue_date","mobile-breakpoint":"10","search":_vm.search,"loading":_vm.loading_status,"loader-height":"10","loading-text":"Cargando ..."},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("PUC")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"success"},on:{"click":_vm.newForm}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.pucs,"label":"Tipo","item-value":"puc_code","item-text":function (item) { return item.puc_code + ' - ' + item.puc_name; },"outlined":"","dense":"","hide-details":"auto"},on:{"change":_vm.changePucs},model:{value:(_vm.puc),callback:function ($$v) {_vm.puc=$$v},expression:"puc"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(_vm.$vuetify.breakpoint.xsOnly)?_c('span',[_c('br'),_c('small',[_vm._v(_vm._s(item.issue_date))])]):_vm._e()]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.note)}})]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [(!_vm.$vuetify.breakpoint.xsOnly)?_c('span',[_vm._v(" "+_vm._s(item.city)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleClick(item)}}},[_vm._v(" mdi-account-edit-outline ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.newOrder(item)}}},[_vm._v(" mdi-file-document-plus-outline ")])]}}],null,true)})],1),_c('PartyForm',{attrs:{"table":_vm.table,"item":_vm.item,"dialog":_vm.dialog,"newItem":_vm.newItem},on:{"close":function($event){_vm.dialog = false},"refresh":function($event){return _vm.refresh()},"newOrder":function($event){return _vm.newOrder(_vm.item)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }