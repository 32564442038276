<template>
  <v-container>
    <v-sheet color="white" elevation="20" class="pa-2 mt-3">
      <v-data-table
        :headers="headers[$vuetify.breakpoint.xsOnly]"
        :items="accounts"
        :items-per-page="10"
        item-key="puc_code"
        sort-by="issue_date"
        mobile-breakpoint="10"
        :search="search"
        :loading="loading_status"
        loader-height="10"
        loading-text="Cargando ..."
        @click:row="handleClick"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>PUC</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn fab dark small color="success" @click="newForm">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>

          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="puc"
                :items="pucs"
                label="Tipo"
                item-value="puc_code"
                :item-text="(item) => item.puc_code + ' - ' + item.puc_name"
                outlined
                dense
                hide-details="auto"
                @change="changePucs"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="search" label="Buscar" class="mx-4" />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
          <span v-if="$vuetify.breakpoint.xsOnly">
            <br />
            <small>{{ item.issue_date }}</small>
          </span>
        </template>
        <template v-slot:[`item.note`]="{ item }">
          <span v-html="item.note"></span>
        </template>
        <template v-slot:[`item.city`]="{ item }">
          <span v-if="!$vuetify.breakpoint.xsOnly">
            {{ item.city }}
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="handleClick(item)">
            mdi-account-edit-outline
          </v-icon>

          <v-icon small class="mr-2" @click="newOrder(item)">
            mdi-file-document-plus-outline
          </v-icon>
        </template>
      </v-data-table>
    </v-sheet>

    <PartyForm
      :table="table"
      :item="item"
      :dialog="dialog"
      :newItem="newItem"
      @close="dialog = false"
      @refresh="refresh()"
      @newOrder="newOrder(item)"
    />
  </v-container>
</template>

<script>
import { webserver, getIssueDate, getToday } from "../../services/webserver.js";
import createItem from "../../utils/createPuc.js";
import PartyForm from "../../components/ClientForm.vue";

export default {
  components: { PartyForm },
  mounted() {
    this.get_items();
  },
  data() {
    return {
      chosenPhoto: null,
      table: "clients",
      validMsg: false,
      broadcast: { email: null, subject: null, msg: null },
      broadcast_dialog: false,
      customToolbar: [
        [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ header: 1 }, { header: 2 }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
      accounts: [],
      puc: null,
      pucs: [],
      dialog: false,
      newItem: false,
      search: "",
      loading_status: false,
      errors: [],
      items: [],
      item: createItem(),
      headers: {
        false: [
          {
            text: "PUC",
            align: "start",
            sortable: true,
            value: "puc_code",
            dataType: "text",
          },
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "puc_name",
            dataType: "text",
          },
          {
            text: "Tipo",
            align: "start",
            sortable: true,
            value: "account_type",
            dataType: "text",
          },
        ],
        true: [
          {
            text: "PUC",
            align: "start",
            sortable: true,
            value: "puc_code",
            dataType: "text",
          },
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "puc_name",
            dataType: "text",
          },
        ],
      },
      issueDateFrom: getIssueDate(5),
      issueDateTo: getToday(),
      groupby: "",
      groups: [],
    };
  },
  methods: {
    changePucs(e) {
      this.accounts = this.items.filter((rg) => rg.puc_code.startsWith(e));
      console.log(e);
    },
    newOrder(e) {
      this.$router.push({ name: "Inicio", params: e });
    },
    handleClick(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    updateLocation(e) {
      this.store.location = JSON.stringify(e);
    },
    changeClients() {
      this.get_items();
    },
    get_items() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "accounting_puc",
        filters: [
          {
            field: "account",
            operator: " IN ",
            value: "'000000','" + this.$store.state.profile.account + "'",
          },
        ],
      };
      console.log(qry);
      this.$store.dispatch("setloading", true);
      webserver("get_dataTable", qry, (data) => {
        console.log(data);
        this.$store.dispatch("setloading", false);
        if (data.length > 0) {
          var ctgry = data.reduce(function (r, a) {
            r[a.account] = r[a.account] || [];
            r[a.account].push(a);
            return r;
          }, Object.create(null));
          this.pucs = ctgry["000000"];
          this.items = ctgry[this.$store.state.profile.account];
          this.accounts = ctgry[this.$store.state.profile.account];
        }
      });
    },
    refresh() {
      this.dialog = false;
      this.get_items();
    },

    newForm() {
      this.item = createItem();
      this.item.account = this.$store.state.profile.account;
      this.item.store = this.$store.state.company.code;
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
